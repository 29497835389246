import { SplitText } from 'gsap/SplitText';
import { wrap } from '../utils/dom';

export default function splitLines(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll('[data-split-lines]');
    elements.forEach((element) => {
        const splitted = new SplitText(element, { type: 'chars,words,lines', reduceWhiteSpace: true });
        const { lines } = splitted;

        lines.forEach((lines) => {
            const wrapper = wrap(lines);
            wrapper.classList.add('splitted-wrapper');
        });
    });
}
