const header = document.querySelector('.js-header');
const burger = document.querySelector('.js-burger');
const commonRootMargin = '-10% 0% -90% 0%';
const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        if (header) {
            if (entry.isIntersecting) {
                const target = entry.target as HTMLElement;
                if (target.dataset.headerTheme == 'dark') {
                    header?.classList.add('dark-theme');
                    burger?.classList.add('dark-theme');
                } else {
                    burger?.classList.remove('dark-theme');
                    header?.classList.remove('dark-theme');
                }
            }
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-header-theme]'));

    if (!elements[0].classList.contains('footer-inner')) {
        if (elements[0].dataset.headerTheme == 'dark') {
            header?.classList.add('dark-theme');
            burger?.classList.add('dark-theme');
        } else {
            burger?.classList.remove('dark-theme');
            header?.classList.remove('dark-theme');
        }
    }

    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-header-theme]'));

    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
        }
    });
}

export default { init, destroy };
