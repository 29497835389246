import { offset } from '../utils/offset';
import { fetchDelegate } from '../dynamic-modules';

const SELECTOR = '.js-button';
const coefficient = 2.25;

const calculatePosition = (
    target: HTMLElement,
    event: MouseEvent,
    additionalElement: HTMLElement,
    buttonWidth?: number,
    buttonHeight?: number,
) => {
    const isSelect = target.classList.contains('js-select-button');
    const targetOffset = offset(target);
    const relX =
        isSelect &&
        buttonWidth &&
        buttonHeight &&
        event.pageX - targetOffset.left > Math.max(buttonWidth, buttonHeight) - buttonWidth * 2
            ? Math.max(buttonWidth, buttonHeight) - buttonHeight * 2
            : event.pageX - targetOffset.left;
    const relY =
        isSelect &&
        buttonWidth &&
        buttonHeight &&
        event.pageY - targetOffset.top > Math.max(buttonWidth, buttonHeight) - buttonHeight * 2
            ? Math.max(buttonWidth, buttonHeight) - buttonHeight * 2
            : event.pageY - targetOffset.top;
    additionalElement.style.top = `${relY}px`;
    additionalElement.style.left = `${relX}px`;
};

const onMouseEnter = (event: any) => {
    const target = event.target.closest(SELECTOR) as HTMLElement;
    const additionalElement = target.querySelector<HTMLElement>('.js-button-decor');
    target.classList.add('is-hovered');
    if (additionalElement) {
        const buttonWidth = target.offsetWidth;
        const buttonHeight = target.offsetHeight;
        calculatePosition(target, event, additionalElement, buttonWidth, buttonHeight);
        additionalElement.style.width = `${Math.max(buttonWidth, buttonHeight) * coefficient}px`;
        additionalElement.style.height = `${Math.max(buttonWidth, buttonHeight) * coefficient}px`;
    }
};

const onMouseLeave = (event: any) => {
    const target = event.target.closest(SELECTOR) as HTMLElement;
    target.classList.remove('is-hovered');
    const additionalElement = target.querySelector<HTMLElement>('.js-button-decor');
    if (additionalElement) {
        calculatePosition(target, event, additionalElement);
        additionalElement.style.width = ``;
        additionalElement.style.height = ``;
    }
};

export async function initButtonHover() {
    const delegate = await fetchDelegate();
    delegate(SELECTOR, 'mouseenter', onMouseEnter, true);
    delegate(SELECTOR, 'mouseleave', onMouseLeave, true);
}
