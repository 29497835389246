import gsap from 'gsap';

function scrollToElement(element: HTMLElement | undefined | null) {
    if (element) {
        gsap.to(window, {
            scrollTo: {
                y: element,
                offsetY: -10
            },
            overwrite: true,
            onComplete: () => {
                document.body.classList.remove('no-scroll');
            },
            duration: 0.8,
        });
    }
}

function onClick(e: Event) {
    e.preventDefault();
    const targetElement = e.target as HTMLElement;
    const linkElement = targetElement.closest<HTMLElement>('[data-scroll-to]');
    if (linkElement) {
        const popupWrapper = linkElement.closest('app-lit-popup');
        popupWrapper?.close();
        if (linkElement.dataset && linkElement.dataset.scrollTo) {
            const element = document.getElementById(linkElement.dataset.scrollTo);
            if (element) {
                scrollToElement(element);
            }
        }
    }
}

let timer: NodeJS.Timeout;

function init(container: HTMLElement | Document = document) {
    clearTimeout(timer);
    const anchorLinks = Array.from(container.querySelectorAll('[data-scroll-to]'));
    anchorLinks.forEach((link) => link.addEventListener('click', onClick));
    if (window.location.hash) {
        timer = setTimeout(() => {
            const element = container.querySelector<HTMLElement>(window.location.hash);
            scrollToElement(element);
        }, 400);
    }
}
function destroy(container: HTMLElement | Document = document) {
    const anchorLinks = Array.from(container.querySelectorAll('[data-scroll-to]'));
    anchorLinks.forEach((link) => link.addEventListener('click', onClick));
    clearTimeout(timer);
}

const _module = { init, destroy };

export default _module;
