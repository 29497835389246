const onOpen = (instance) => {
    const tabName = instance.detail.triggerElement.dataset.tabOnOpen;
    const currentButton = instance.target.querySelector(`[data-tabs-toggler="${tabName}"]`);
    const clickEvent = new Event('click');
    currentButton.dispatchEvent(clickEvent);
};

export default function handleFeedbackPopupOpenInit() {
    const popup = document.querySelector('app-lit-popup[data-lit-popup="feedback-popup"]');

    if (popup) {
        popup.addEventListener('open', onOpen);
    }
}
