import { fetchSlimSelect } from '../dynamic-modules';

const map = new WeakMap<HTMLSelectElement, any>();

const onScroll = () => {
    const selectElements = Array.from(document.querySelectorAll<HTMLSelectElement>('.js-select'));

    selectElements.forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.close();
        }
    });
};

async function init(container: HTMLElement | Document = document) {
    const SlimSelect = await fetchSlimSelect();

    const selectElements = Array.from(container.querySelectorAll<HTMLSelectElement>('.js-select'));

    selectElements.forEach((el) => {
        const instance = new SlimSelect({
            select: el,
            showSearch: false,
            onChange: (event) => {
                if (el.classList.contains('js-lang-select') && event && event.value) {
                    window.location = event.value;
                }
            },
        });
        map.set(el, instance);
    });

    document.addEventListener('scroll', onScroll);
}

function destroy(container: HTMLElement | Document = document) {
    const selectElements = container.querySelectorAll<HTMLSelectElement>('.js-select');

    selectElements.forEach((el) => {
        const select = map.get(el);

        if (select) {
            select.destroy();
            map.delete(el);
        }
    });

    document.removeEventListener('scroll', onScroll);
}

const _module = { init, destroy };

export default _module;
