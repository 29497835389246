const map = new WeakMap();

function init(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-reveal]'));
    elements.forEach((element) => {
        const rootMargin = element.dataset.rootMargin || '0% -10% 0% -10%';
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        entry.target.classList.add('is-reveal');
                        setTimeout(() => {
                            entry.target.classList.add('is-reveal-complete');
                        }, 600);
                    }
                });
            },
            {
                rootMargin,
                threshold: [0, 1],
            },
        );
        observer.observe(element);
        map.set(element, observer);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const elements = Array.from(container.querySelectorAll('[data-reveal]'));
    elements.forEach((element) => {
        const observer = map.get(element);

        if (observer) {
            observer.unobserve(element);
            map.delete(element);
        }
    });
}

const _module = { init, destroy };

export default _module;
