export default function splitLines(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll('[data-wrap-element]');
    elements.forEach((element) => {
        const wrapper = document.createElement('div');
        const innerWrapper = document.createElement('div');
        Array.from(element.children).forEach((child) => {
            innerWrapper.appendChild(child);
        });
        wrapper.appendChild(innerWrapper);
        element.innerHTML = '';
        element.appendChild(wrapper);
        wrapper.classList.add('wrapped-element');
    });
}
