import { SplitText } from 'gsap/SplitText';
import { wrap } from '../utils/dom';

export default function splitChars(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll('[data-split-chars]');
    elements.forEach((element) => {
        const splitted = new SplitText(element, { type: 'chars', reduceWhiteSpace: false });
        const { chars } = splitted;

        chars.forEach((chars) => {
            const wrapper = wrap(chars);
            wrapper.classList.add('splitted-chars-wrapper');
        });
    });
}
