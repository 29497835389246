import barba from '@barba/core';
import { lazyload } from '../inits/lazyload';
import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import reveal from '../inits/reveal';
import splitLines from '../inits/split-lines';
import splitChars from '../inits/split-chars';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import headerThemeObserver from '../inits/header-theme-observer';
import wrapPhotos from '../inits/wrap-photos';
import anchors from '../inits/anchors';

const enterTl = gsap.timeline({ paused: true, delay: 0.6 });

export function initBaseView() {
    barba.hooks.enter((data) => {
        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        enterTl.restart();
    });

    barba.hooks.beforeEnter((data) => {
        ScrollTrigger.refresh();
        document.body.classList.remove('no-scroll');
        gsap.to(window, {
            scrollTo: { y: 0 },
            duration: 0,
        });

        if (data) {
            document.body.classList.add(data.next.namespace);
        }
        reveal.init(data?.next.container);
        anchors.init(data?.next.container);
        headerThemeObserver.init(data?.next.container);
        splitLines(data?.next.container);
        splitChars(data?.next.container);
        wrapPhotos(data?.next.container);
        enterTl
            .set('.a-header-item', { transition: 'none' })
            .from(
                '.a-header-item',
                { opacity: 0, y: 10, transformOrigin: 'top left', rotate: '3deg', stagger: 0.05, duration: 0.3 },
                0.6,
            )
            .set('.a-header-item', { clearProps: 'all' })
            .from('.a-page-bg', { opacity: 0 }, 0.3)
            .from('.a-page-item', { opacity: 0, y: 20, stagger: 0.08 }, 0.4);
    });

    barba.hooks.beforeLeave((data) => {
        reveal.destroy(data?.current.container);
        anchors.destroy(data?.current.container);
        headerThemeObserver.destroy(data?.current.container);
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterLeave((data) => {
        if (data) {
            document.body.classList.remove(data.current.namespace);
        }
    });
}
