const onFocus = (event: Event) => {
    const target = event.target as HTMLInputElement;
    target.classList.add('is-fill');
};

const onBlur = (event: Event | HTMLInputElement) => {
    const target =
        event instanceof HTMLInputElement || event instanceof HTMLTextAreaElement
            ? event
            : (event.target as HTMLInputElement);
    target.value === '' ? target.classList.remove('is-fill') : target.classList.add('is-fill');
};

function init(container: HTMLElement | Document = document) {
    const inputs = Array.from(container.querySelectorAll<HTMLInputElement>('.js-input'));

    inputs.forEach((input) => {
        input.addEventListener('focus', onFocus);
        input.addEventListener('blur', onBlur);
        onBlur(input);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const inputs = Array.from(container.querySelectorAll<HTMLInputElement>('.js-input'));

    inputs.forEach((input) => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
    });
}

const _module = { init, destroy };

export default _module;
